import React, { Component } from 'react';
import { Container } from 'reactstrap';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import { Helmet } from 'react-helmet';

import './App.css';

import profile from './static/images/profile.jpg';

import NavigationBar from './components/utilities/NavigationBar/NavigationBar';
import Home from './components/pages/Home/Home';
import About from './components/pages/About/About';
import Works from './components/pages/Works/Works';
import Blog from './components/pages/Blog/Blog';
import Contact from './components/pages/Contact/Contact';
// import Weather from './components/pages/Weather/Weather';

import BlogTemplate from './components/blog-posts/BlogTemplate';
import WorkTemplate from './components/work-posts/WorkTemplate';

class App extends Component {
  render() {
    return (
      // <Router basename={process.env.PUBLIC_URL} >
      <Router basename="/" >
        <div className="App" >
          <Helmet>
            <title>Thanakrit Lee</title>
            <meta name="description" content="Thanakrit Lee: a blockchain developer based in Bangkok, Thailand." />
            <meta name="og:image" content={profile} />
          </Helmet>
          <Container fluid>
            <NavigationBar />
            <Route exact path="/" component={Home} />
            <Route path="/about" component={About} />
            <Route path="/works" component={Works} />
            <Route path="/blog" component={Blog} />
            <Route path="/contact" component={Contact} />
            {/* <Route path="/weather" component={Weather} /> */}
            <Route exact path="/posts/:postname" component={BlogTemplate} />
            <Route exact path="/projects/:postname" component={WorkTemplate} />
          </Container>
        </div>
      </Router>
    );
  }
}

export default App;
