export default (postname) => {
  return data[postname];
};

const data =  {
  "road_surface_estimator": {
    title: "Road Surface Estimator | Thanakrit Lee", 
    description: "A web application use to calculate the estimated total surface area of all roads in a selected 1 square kilometre area.", 
    image: "https://i.imgur.com/NxC3P4G.png"
  },
  "anon_chat": {
    title: "Anon Chat | Thanakrit Lee", 
    description: "A web chat application where users are anonymous.", 
    image: "https://i.imgur.com/5zHbAYv.png"
  }
};