import React, { Component } from 'react';
import './Contact.css';
import { Row, Col } from 'reactstrap';
import { Helmet } from 'react-helmet';

class Contact extends Component {
  render() {
    return (
      <div className="page" >
        <Helmet>
          <title>Thanakrit Lee | Contact</title>
          <meta name="description" content="Thanakrit Lee's contacts." />
        </Helmet>
        <Row className="desc" >
          <Col sm="12" md={{ size: 6, offset: 3}} >
            <p>
              <strong>Email Address:</strong> thanakrit.lee.2014@gmail.com
            </p>
          </Col>
        </Row>
      </div>
    );
  }
}

export default Contact;