import React, { Component } from 'react';
import './About.css';
import { Row, Col, Media } from 'reactstrap';
import { Helmet } from 'react-helmet';

import profile from '../../../static/images/profile.jpg';

class About extends Component {
  render() {
    return (
      <div className="page" >
        <Helmet>
          <title>Thanakrit Lee | About</title>
          <meta name="description" content="About Thanakrit Lee." />
          <meta name="og:image" content={profile} />
        </Helmet>
        <Media object src={profile} className="profile" />
        <Row className="desc" >
          <Col sm="12" md={{ size: 8, offset: 2 }} >
            <p>
              Hello, I'm <strong>Thanakrit Lee</strong>, and I'm currently a software engineer.
              I graduated with a bachelor degree in computer science at Monash University, 
              Melbourne, Australia.
            </p>
          </Col>
        </Row>
        <Row className="desc" >
          <Col sm="12" md={{ size: 8, offset: 2 }} > 
            <p>
              Anyhow, this is my web site. The client is written with React.js using create-react-app
              , it's served using Go's net/http server, and hosted on AWS.
            </p>
          </Col>
        </Row>
    </div>
    );
  }
}

export default About;