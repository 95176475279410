import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import {
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  NavLink
} from 'reactstrap';

class NavigationBar extends Component {
  constructor(props) {
    super(props);

    this.toggle = this.toggle.bind(this);
    this.toggleCloseNav = this.toggleCloseNav.bind(this);
    this.state = {
      isOpen: false
    };
  }
  
  toggle() {
    this.setState({
      isOpen: !this.state.isOpen
    });
  }

  toggleCloseNav() {
    this.setState({
      isOpen: this.state.isOpen ? !this.state.isOpen : this.state.isOpen
    });
  }

  render() {
    return (
      <div>
        <Navbar light expand="md" >
          <NavbarBrand onClick={this.toggleCloseNav} tag={Link} to="/" >Thanakrit Lee</NavbarBrand>
          <NavbarToggler onClick={this.toggle} />
          <Collapse isOpen={this.state.isOpen} navbar >
            <Nav className="ml-auto" navbar >
            <NavItem>
                <NavLink onClick={this.toggleCloseNav} tag={Link} to="/" >Home</NavLink>
              </NavItem>
            <NavItem>
                <NavLink onClick={this.toggleCloseNav} tag={Link} to="/about" >About</NavLink>
              </NavItem>
              <NavItem>
                <NavLink onClick={this.toggleCloseNav} tag={Link} to="/works" >Works</NavLink>
              </NavItem>
              <NavItem>
                <NavLink onClick={this.toggleCloseNav} tag={Link} to="/blog" >Blog</NavLink>
              </NavItem>
              <NavItem>
                <NavLink onClick={this.toggleCloseNav} tag={Link} to="/contact" >Contact</NavLink>
              </NavItem>
              {/* <NavItem>
                <NavLink onClick={this.toggleCloseNav} tag={Link} to="/weather" >Weather</NavLink>
              </NavItem> */}
              <NavItem>
                <NavLink href="https://github.com/thanakritlee" >GitHub</NavLink>
              </NavItem>
              <NavItem>
                <NavLink href="https://www.linkedin.com/in/thanakrit-lee/" >LinkedIn</NavLink>
              </NavItem>
            </Nav>
          </Collapse>
        </Navbar>
      </div>
    );
  }
}

export default NavigationBar;