import React, { Component } from 'react';
import './Home.css';
import { Container, Row, Col, Jumbotron } from 'reactstrap';
import { Helmet } from 'react-helmet';

import profile from '../../../static/images/profile.jpg';

class Home extends Component {
  render() {
    return (
      <div className="page" >
        <Helmet>
          <title>Thanakrit Lee | Blockchain/Web/Software | Bangkok</title>
          <meta name="description" content="Thanakrit Lee: software engineer based in Bangkok, Thailand." />
          <meta name="og:image" content={profile} />
        </Helmet>
        <Jumbotron fluid className="jumbotron" >
          <Container fluid>
            <h1 className="display-5" >Thanakrit Lee</h1>
            <p className="lead" >I'm a software engineer with background in API development and Blockchain technologies.</p>
          </Container>
        </Jumbotron>
          <Row className="desc" >
            <Col sm="12" md={{ size: 8, offset: 2 }} > 
              <p>
                I'm currently a software engineer at Feed Ingredients Trading Business Group (CP Group).
              </p>
            </Col>
          </Row>
          <Row className="desc" >
            <Col sm="12" md={{ size: 8, offset: 2 }} > 
              <p>
                My interests include web technologies, blockchain, system administration, Linux, and DevOps.
                The purposes of this web site is to act as a personal portfolio showing my work, and to share my working knowledge 
                through written blog posts.
              </p>
            </Col>
          </Row>
      </div>
    );
  }
}

export default Home;