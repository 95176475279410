const posts = {
  "posts": [
    {
      "img": "https://upload.wikimedia.org/wikipedia/commons/4/4c/Typescript_logo_2020.svg",
      "alt": "Testing Software and Mocking Services",
      "title": "Testing Software and Mocking Services (with Loopback 4)",
      "date": "26-07-2021",
      "description": "Discuss method for testing software and mocking services with examples in NodeJS Loopback 4.",
      "link": "/posts/testing_software_and_mocking_services_with_loopback"
    },
    {
      "img": "https://i.imgur.com/8VWOLCs.png",
      "alt": "GoLang nil Channel",
      "title": "How to use GoLang's nil Channel",
      "date": "12-01-2020",
      "description": "A post showing a use case of a nil channel.",
      "link": "/posts/golang_nil_channel"
    },
    {
      "img": "https://i.imgur.com/uZumWRp.png",
      "alt": "Tap to Click Xorg with Libinput",
      "title": "How to Enable Touchpad Tap-to-Click in Xorg on Linux",
      "date": "14-11-2019",
      "description": "A tutorial describing the steps I took to enable touchpad tap-to-click function permanently.",
      "link": "/posts/enable_touchpad_tap_clicking"
    },
    {
      "img": "https://i.imgur.com/JqaUs41.png",
      "alt": "ACL",
      "title": "Implementing Fabric ACL with CID",
      "date": "20-05-2019",
      "description": "A tutorial on how to implement a basic Hyperledger Fabric ACL using the Fabric CID package.",
      "link": "/posts/implementing_fabric_acl_with_cid"
    },
    {
      "img": "https://i.imgur.com/9D5bVTr.png",
      "alt": "Kubernetes and Azure",
      "title": "Deploy Elastic Stack to Kubernetes",
      "date": "09-03-2019",
      "description": "Discuss how to test and deploy an Elastic Stack application to a Kubernetes cluster on Azure cloud service.",
      "link": "/posts/deploy_elastic_stack_to_kubernetes"
    },
    {
      "img": "https://i.imgur.com/Vlt0Q2q.jpg",
      "alt": "React-Helmet",
      "title": "React app into static HTML with meta tags",
      "date": "24-01-2019",
      "description": "A tutorial on how to pre-renders React App into static HTML, and add meta tags to your page.",
      "link": "/posts/generate_static_site"
    },
    {
      "img": "https://i.imgur.com/rn9uS6e.jpg",
      "alt": "Firework",
      "title": "The start of a journey",
      "date": "20-01-2019",
      "description": "A description of my journey in designing, developing, and deploying this website.",
      "link": "/posts/start_of_a_journey"
    },
  ]
};



export default posts;