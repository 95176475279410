const posts = {
  "posts": [
    {
      "img": "https://i.imgur.com/5zHbAYv.png",
      "alt": "Anon Chat",
      "title": "Anon Chat",
      "date": "02-04-2019",
      "description": "A web chat application where users are anonymous.",
      "link": "/projects/anon_chat"
    },
    {
      "img": "https://i.imgur.com/NxC3P4G.png",
      "alt": "Road Surface Estimator",
      "title": "Road Surface Estimator",
      "date": "10-05-2018",
      "description": "A web application use to calculate the estimated total surface area of all roads in a selected 1 square kilometre area.",
      "link": "/projects/road_surface_estimator"
    },
  ]
};



export default posts;