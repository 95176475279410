import React, { Component } from 'react';
import './BlogTemplate.css';
import ReactMarkdown from 'react-markdown';
import { Row, Col } from 'reactstrap';
import { Helmet } from 'react-helmet';
import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
  RedditShareButton,
  LineShareButton,

  FacebookIcon,
  TwitterIcon,
  LinkedinIcon,
  RedditIcon,
  LineIcon,
} from 'react-share';

import CodeBlock from './CodeBlock';
import GetData from './Data';

// Markdown renderer for images (img tag).
function Image(props) {
  return <img alt="" {...props} />
}

class BlogTemplate extends Component {
  constructor(props) {
    super(props);
    
    this.state = {
      markdown: null
    };
  }

  componentWillMount() {
    const { postname } = this.props.match.params;
    const { title, description, image } = GetData(postname);
    // const { title, description, image } = this.props.location.state
    const markdownFile = require(`./posts/${postname}.md`);

    fetch(markdownFile)
      .then((response) => {
        return response.text();
      })
      .then((markdown) => {
        this.setState({
          postname: postname,
          markdown: markdown,
          title: title,
          description: description,
          image: image,
          postURL: "https://thanakritlee.dev/posts/" + postname
        });
      });
  }

  render() {
    return (
      <div className="page-blog" >
        <Helmet>
          <title>{ this.state.title }</title>
          <meta name="description" content={ this.state.description } />
          <meta name="og:image" content={ this.state.image } />
        </Helmet>
        <Row className="desc" >
          <Col sm="12" md={{ size: 8, offset: 2 }} >
            <ReactMarkdown 
              source={ this.state.markdown }
              renderers={{ code: CodeBlock, image: Image }}
              escapeHtml={ false }
              />
          </Col>
        </Row>
        <Row className="desc">
          <Col sm="12" md={{ size: 8, offset: 2 }} >
            <Row>
              <Col align="center" >
                <FacebookShareButton
                  url={this.state.postURL}
                  quote={this.state.title}
                  className="blogShareButton">
                  <FacebookIcon
                    size={32}
                    round />
                </FacebookShareButton>
              </Col>
              <Col align="center" >
                <TwitterShareButton
                  url={this.state.postURL}
                  title={this.state.title}
                  className="blogShareButton">
                  <TwitterIcon
                    size={32}
                    round />
                </TwitterShareButton>
              </Col>
              <Col align="center" >
                <LinkedinShareButton
                  url={this.state.postURL}
                  title={this.state.title}
                  windowWidth={750}
                  windowHeight={600}
                  className="blogShareButton">
                  <LinkedinIcon
                    size={32}
                    round />
                </LinkedinShareButton>
              </Col>
              <Col align="center">
                <LineShareButton
                  url={this.state.postURL}
                  title={this.state.title}
                  className="blogShareButton">
                  <LineIcon
                    size={32}
                    round />
                </LineShareButton>
              </Col>
              <Col align="center" >
                <RedditShareButton
                  url={this.state.postURL}
                  title={this.state.title}
                  windowWidth={660}
                  windowHeight={460}
                  className="blogShareButton">
                  <RedditIcon
                    size={32}
                    round />
                </RedditShareButton>
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
    );
  }
}

export default BlogTemplate;