import React, { Component } from 'react';
import './Works.css';
import { Row, Col, Card, Button, CardImg, CardTitle, CardText, CardDeck, CardSubtitle, CardBody } from 'reactstrap';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import posts from './posts';

class Works extends Component {

  constructor(props) {
    super(props);
    this.state = {
      posts: posts
    };
  }

  getWorkPosts(post) {

    // If there are no posts, then return empty card deck.
    if (post.posts.length === 0) {
      return (
        <Row className="workCardRow" >
          <Col sm="12" md={{ size: 8, offset: 2 }} >
            <CardDeck>
              <Card className="workCardHidden" >
              </Card>
              <Card className="workCardHidden" >
              </Card>
              <Card className="workCardHidden" >
              </Card>
            </CardDeck>
          </Col>
        </Row>
      );
    }

    // Collect all cards in a deck, and return it once the deck is complete.
    let cardDeck = [];
    
    // A collection of cardDeck.
    let deckCollection = [];

    // Boolean to keep track if a card deck is returned.
    // This is needed in the case where there are not enough cards to complete a deck,
    // but a deck must be return any way. This will return the incomplete deck after the
    // forEach loop.
    let deckReturned = false;
    post.posts.forEach((element, index) => {
      let card = (
        <Card>
          <CardImg top width="100%" src={element.img} alt={element.alt} />
          <CardBody>
            <CardTitle className="workCardTitle" >{element.title}</CardTitle>
            <CardSubtitle className="workCardSubtitle" >{element.date}</CardSubtitle>
            <CardText>
              {element.description}
            </CardText>
          </CardBody>
          <Link to={{ pathname: element.link }}>
            <Button className="buttonRead" >Read</Button>
          </Link>
        </Card>
      );

      // If current card is in a deck.
      if ((index + 1) % 3 !== 0) {
        // Set boolean to false.
        deckReturned = false;

        // If not last card in the deck yet, add the card to the cardDeck array.
        cardDeck.push(card);
      } else {
        // Else, if current is last card in the deck, then complete the deck and add it to the deck collection.
        deckCollection.push(
          <Row className="workCardRow" >
            <Col sm="12" md={{ size: 8, offset: 2 }} >
              <CardDeck>
                {cardDeck[0]}
                {cardDeck[1]}
                {card}
              </CardDeck>
            </Col>
          </Row>
        );

        // Empty the card deck.
        cardDeck = [];
        deckReturned = true;
      }
    })

    // If deck is incomplete, return the deck any way.
    if (!deckReturned) {
      let deck;
      if (cardDeck.length === 1) {
        deck = (
          <CardDeck>
            { cardDeck[0] }
            <Card className="workCardHidden" >
            </Card>
            <Card className="workCardHidden" >
            </Card>
          </CardDeck>
        );
      } else {
        deck = (
          <CardDeck>
            { cardDeck[0] }
            { cardDeck[1] }
            <Card className="workCardHidden" >
            </Card>
          </CardDeck>
        );
      }
      // Add the incomplete card deck to the deck collection.
      deckCollection.push(
        <Row className="workCardRow" >
          <Col sm="12" md={{ size: 8, offset: 2 }} >
            { deck }
          </Col>
        </Row>
      )
    }

    // Return all deck inside the deck collection.
    return deckCollection;

  }

  render() {
    return (
      <div className="page" >
        <Helmet>
          <title>Thanakrit Lee | Works</title>
          <meta name="description" content="Thanakrit Lee's works and projects." />
        </Helmet>
        { this.getWorkPosts(this.state.posts) }
      </div>
    );
  }

}

export default Works;