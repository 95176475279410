import React, { Component } from 'react';
import './WorkTemplate.css';
import ReactMarkdown from 'react-markdown';
import { Row, Col } from 'reactstrap';
import { Helmet } from 'react-helmet';

import CodeBlock from './CodeBlock';
import GetData from './Data';

// Markdown renderer for images (img tag).
function Image(props) {
  return <img alt="" {...props} />
}

class WorkTemplate extends Component {
  constructor(props) {
    super(props);
    
    this.state = {
      markdown: null
    };
  }


  componentWillMount() {
    const { postname } = this.props.match.params;
    const { title, description, image } = GetData(postname);
    // const { title, description, image } = this.props.location.state
    const markdownFile = require(`./posts/${postname}.md`);

    fetch(markdownFile)
      .then((response) => {
        return response.text();
      })
      .then((markdown) => {
        this.setState({
          markdown: markdown,
          title: title,
          description: description,
          image: image,
        });
      });
  }

  render() {
    return (
      <div className="page-work" >
        <Helmet>
          <title>{ this.state.title }</title>
          <meta name="description" content={ this.state.description } />
          <meta name="og:image" content={ this.state.image } />
        </Helmet>
        <Row className="desc" >
          <Col sm="12" md={{ size: 8, offset: 2 }} >
            <ReactMarkdown 
              source={ this.state.markdown }
              renderers={{ code: CodeBlock, image: Image }}
              escapeHtml={ false }
              />
          </Col>
        </Row>
      </div>
    );
  }
}

export default WorkTemplate;