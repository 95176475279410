export default (postname) => {
  return data[postname];
};

const data =  {
  "start_of_a_journey": {
    title: "The start of a Journey | Thanakrit Lee", 
    description: "In this post, I'm going to be describing the steps I took in designing, developing, and deploying this web site.", 
    image: "https://i.imgur.com/rn9uS6e.jpg"
  },
  "generate_static_site": {
    title: "React App into Static HTML with Meta Tags | Thanakrit Lee", 
    description: "In this post, I'll show you how to pre-render a normal React app (created with create-react-app) into static HTML, and I'll also show you how to add meta tags to your page to help with SEO and SMO.", 
    image: "https://i.imgur.com/Vlt0Q2q.jpg"
  },
  "deploy_elastic_stack_to_kubernetes": {
    title: "Deploy Elastic Stack to Kubernetes | Thanakrit Lee", 
    description: "This post will discuss how to test and deploy an Elastic Stack application to a Kubernetes cluster on Azure cloud service.", 
    image: "https://i.imgur.com/9D5bVTr.png"
  },
  "implementing_fabric_acl_with_cid": {
    title: "Implementing Fabric ACL with CID | Thanakrit Lee",
    description: "This post will show you how to implement a basic Hyperledger Fabric ACL using the Fabric CID package.",
    image: "https://i.imgur.com/JqaUs41.png"
  },
  "enable_touchpad_tap_clicking": {
    title: "How to Enable Touchpad Tap-to-Click in Xorg on Linux | Thanakrit Lee",
    description: "This tutorial describe the steps I took to enable touchpad tap-to-click function permanently.",
    image: "https://i.imgur.com/uZumWRp.png"
  },
  "golang_nil_channel": {
    title: "GoLang nil Channel | Thanakrit Lee",
    description: "A post showing a use case of a nil channel.",
    image: "https://i.imgur.com/8VWOLCs.png"
  },
  "testing_software_and_mocking_services_with_loopback": {
    title: 'Testing Software and Mocking Services (with Loopback 4) | Thanakrit Lee',
    descriptiong: 'Discuss method for testing software and mocking services with examples in NodeJS Loopback 4.',
    image: 'https://upload.wikimedia.org/wikipedia/commons/4/4c/Typescript_logo_2020.svg'
  }
};